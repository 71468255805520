<template>
  <b-container fluid class="content">
    <div v-if="curpage == 'list'">
      <!-- list -->
      <b-card>
        <el-button type="primary" icon="el-icon-plus" @click="new_card_product" v-acl:CardEdit.enable.show>
          {{ $t("New") }}
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="card_wizard" v-acl:CardEdit.enable.show>
          {{ $t("Card Wizard") }}
        </el-button>
        <el-button type="primary" icon="el-icon-search" @click="card_inquiry">
          {{ $t("Card Inquiry") }}
        </el-button>
        <el-table
          stripe
          class="table-responsive table product-table mt-2"
          style="width: 100%"
          header-row-class-name="thead-light"
          :data="card_product_list"
          :cell-style="{ textAlign: 'center' }"
          @row-click="edit_card_product"
        >
          <el-table-column
            class="p-0"
            align="center"
          >
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Barcode") }}</div>
            </template>
            <template v-slot="{ row }">
              {{ row.Barcode }}
            </template>
          </el-table-column>
          <el-table-column
            class="p-0"
            align="center"
          >
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Name") }}</div>
            </template>
            <template v-slot="{ row }">
              {{
                row.Name2 && show_name2
                  ? row.Name1 + "(" + row.Name2 + ")"
                  : row.Name1
              }}
            </template>
          </el-table-column>
          <el-table-column
            class="p-0"
            align="center"
          >
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Type") }}</div>
            </template>
            <template v-slot="{ row }">
              {{
                $t(row.Type)
              }}
            </template>
          </el-table-column>
          <el-table-column
            class="p-0"
            align="center"
          >
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Status") }}</div>
            </template>
            <template v-slot="{ row }">
              {{
                row.Status
              }}
            </template>
          </el-table-column>
          <el-table-column
            class="p-0"
            align="center"
          >
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Price") }}</div>
            </template>
            <template v-slot="{ row }">
              {{
                parseFloat(row.APrice).toFixed(2)
              }}
            </template>
          </el-table-column>
        </el-table>

        <el-row style="margin-top: 20px">
          <el-col :span="20">
            <div>
              <b-pagination
                v-model="current_page"
                :total-rows="page_total"
                :per-page="per_page"
              ></b-pagination>
            </div>
          </el-col>
          <el-col :span="4">
            <div>
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  {{ $t("Max Rows per page") }} {{ this.per_page
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="page_option in per_page_option"
                    :key="page_option"
                    :command="page_option"
                    >{{ page_option }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-col>
        </el-row>
      </b-card>
    </div>
    <div v-if="curpage == 'edit'">
      <!------------------------------ buttons -------------------------->
      <div class="text-left" style="margin-top: 10px; margin-bottom: 10px">
        <el-button type="primary" @click="save" :disabled="freeze_button" v-acl:CardEdit.enable.show>
          {{ $t("Save") }}
        </el-button>
        <el-button @click="cancel_click">
          {{ $t("Back") }}
        </el-button>
      </div>

      <el-collapse v-model="activeCollapse1">
        <el-collapse-item :title="$t('Base')" name="base">
          <el-row :gutter="10">
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Name') + '1:'">
                <b-input
                  class="form-control height_adjust_input left_padding"
                  v-model="cur_card_product.Name1"
                  :disabled="disable_all"
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Name') + '2:'">
                <b-input
                  class="form-control height_adjust_input left_padding"
                  v-model="cur_card_product.Name2"
                  :disabled="disable_all"
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2 w-100">
                <b-input-group-prepend
                  :style="{ flex: '1' }"
                  class="group-append-text"
                  is-text
                >
                  {{ $t("Status") + ":" }}
                </b-input-group-prepend>
                <b-input-group-append is-text>
                  <b-form-checkbox
                    class="form-checkbox"
                    v-model="cur_card_product.Status"
                    value="Active"
                    unchecked-value="Deactive"
                    switch
                    inline
                    style="float: right"
                    :disabled="disable_all"
                  >
                  </b-form-checkbox>
                </b-input-group-append>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Type') + ':'">                  
                <el-select
                  class="el-dropdown4"
                  v-model="cur_card_product.Type"
                  :disabled="disable_all || cur_card_product.IDn != 0"
                  @change="onchange_type"
                >
                  <el-option
                    v-for="(card_type, idx) in card_type_list"
                    :key="idx"
                    :value="card_type.value"
                    :label="$t(card_type.label)"
                  >
                  </el-option>
                </el-select>
              </b-input-group>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Department') + ':'">                  
                <el-select
                  class="el-dropdown4"
                  v-model="cur_card_product.DepartmentIDn"
                  :disabled="disable_all"
                >
                  <el-option
                    :value="0"
                    label=" - "
                    @click.native="changed_department(0)"
                  >
                  </el-option>
                  <el-option
                    v-for="(depart, idx) in department_list"
                    :key="idx"
                    :value="depart.IDn"
                    :label="depart.Name1 + ((show_name2 && depart.Name2) ? (' - ' + depart.Name2) : '')"
                    @click.native="changed_department(depart)"
                  >
                  </el-option>
                </el-select>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Category') + ':'">                  
                <el-select
                  class="el-dropdown4"
                  v-model="cur_card_product.CategoryIDn"
                  :disabled="disable_all"
                >
                  <el-option
                    :value="0"
                    label=" - "
                  >
                  </el-option>
                  <el-option
                    v-for="(category, idx) in category_list"
                    :key="idx"
                    :value="category.IDn"
                    :label="category.Name1 + ((show_name2 && category.Name2) ? (' - ' + category.Name2) : '')"
                  >
                  </el-option>
                </el-select>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Barcode') + ':'">
                <b-input
                  class="form-control height_adjust_input left_padding"
                  v-model="cur_card_product.Barcode"
                  :disabled="disable_all"
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('PLU') + ':'">
                <b-input
                  class="form-control height_adjust_input left_padding"
                  v-model="cur_card_product.PLU"
                  disabled
                />
              </b-input-group>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Price') + ':'">
                <b-input
                  class="form-control height_adjust_input left_padding"
                  v-model="cur_card_product.APrice"
                  @keypress="onlyNumber"
                  :disabled="disable_all"
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Default Balance') + ':'">
                <b-input
                  class="form-control height_adjust_input left_padding"
                  v-model="cur_card_product.BPrice"
                  @keypress="onlyNumber"
                  :disabled="disable_all || disable_balance"
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Bonus') + ':'">
                <b-input
                  class="form-control height_adjust_input left_padding"
                  v-model="cur_card_product.DPrice"
                  @keypress="onlyNumber"
                  :disabled="disable_all || disable_bonus"
                />
                <div class="input_percentage">%</div>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Discount Rate') + ':'">
                <b-input
                  class="form-control height_adjust_input left_padding"
                  v-model="cur_card_product.Discount"
                  @keypress="onlyNumber"
                  :disabled="disable_all || disable_discount"
                />
                <div class="input_percentage">%</div>
              </b-input-group>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Charge Mode') + ':'">                  
                <el-select
                  class="el-dropdown4"
                  v-model="cur_card_product.ChargeMode"
                  :disabled="disable_all || cur_card_product.IDn != 0 || disable_charge_mode"
                  @change="onchange_charge_mode"
                >
                  <el-option
                    v-for="(mode, idx) in charge_mode_list"
                    :key="idx"
                    :value="mode.value"
                    :label="$t(mode.label)"
                  >
                  </el-option>
                </el-select>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2 w-100">
                <b-input-group-prepend
                  :style="{ flex: '1' }"
                  class="group-append-text"
                  is-text
                >
                  {{ $t("Reloadable") + ":" }}
                </b-input-group-prepend>
                <b-input-group-append is-text>
                  <b-form-checkbox
                    class="form-checkbox"
                    v-model="cur_card_product.CanReload"
                    value="1"
                    unchecked-value="0"
                    switch
                    inline
                    style="float: right"
                    :disabled="disable_all"
                  >
                  </b-form-checkbox>
                </b-input-group-append>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2 w-100">
                <b-input-group-prepend
                  :style="{ flex: '1' }"
                  class="group-append-text"
                  is-text
                >
                  {{ $t("Transferable") + ":" }}
                </b-input-group-prepend>
                <b-input-group-append is-text>
                  <b-form-checkbox
                    class="form-checkbox"
                    v-model="cur_card_product.CanTransfer"
                    value="1"
                    unchecked-value="0"
                    switch
                    inline
                    style="float: right"
                    :disabled="disable_all || disable_transfer"
                  >
                  </b-form-checkbox>
                </b-input-group-append>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2 w-100">
                <b-input-group-prepend
                  :style="{ flex: '1' }"
                  class="group-append-text"
                  is-text
                >
                  {{ $t("Enable SerialNumber") + ":" }}
                </b-input-group-prepend>
                <b-input-group-append is-text>
                  <b-form-checkbox
                    class="form-checkbox"
                    v-model="cur_card_product.EnableSerialNo"
                    value="1"
                    unchecked-value="0"
                    switch
                    inline
                    style="float: right"
                    :disabled="disable_all || cur_card_product.IDn != 0"
                  >
                  </b-form-checkbox>
                </b-input-group-append>
              </b-input-group>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2 w-100">
                <b-input-group-prepend
                  :style="{ flex: '1' }"
                  class="group-append-text"
                  is-text
                >
                  {{ $t("Is Dynamic Create") + ":" }}
                </b-input-group-prepend>
                <b-input-group-append is-text>
                  <b-form-checkbox
                    class="form-checkbox"
                    v-model="cur_card_product.IsDynamic"
                    value="1"
                    unchecked-value="0"
                    switch
                    inline
                    style="float: right"
                    :disabled="disable_all || cur_card_product.IDn != 0"
                  >
                  </b-form-checkbox>
                </b-input-group-append>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Expiry Period') + ':'">
                <el-row class="expire_input">
                  <el-col :span="16">
                    <b-input
                      class="form-control height_adjust_input left_padding"
                      v-model="cur_card_product.ExpiryPeriodNum"
                      @keypress="onlyNumber"
                      :disabled="disable_all || disable_expiry_period"
                    />
                  </el-col>
                  <el-col :span="8">
                    <el-select
                      class="el-dropdown5"
                      v-model="cur_card_product.ExpiryPeriodUnit"
                      :disabled="disable_all || disable_expiry_period"
                    >
                      <el-option
                        v-for="(unit, idx) in period_unit_list_show"
                        :key="idx"
                        :value="unit.value"
                        :label="unit.name"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                </el-row>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Tax Class') + ':'">                  
                <el-select
                  class="el-dropdown4"
                  v-model="cur_card_product.TaxTypeIDn"
                  :disabled="disable_all"
                >
                  <el-option
                    v-for="(tax, idx) in tax_typelist"
                    :key="idx"
                    :value="tax.IDn"
                    :label="tax.Name"
                  >
                  </el-option>
                </el-select>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2 w-100">
                <b-input-group-prepend
                  :style="{ flex: '1' }"
                  class="group-append-text"
                  is-text
                >
                  {{ $t("None Revenue") + ":" }}
                </b-input-group-prepend>
                <b-input-group-append is-text>
                  <b-form-checkbox
                    class="form-checkbox"
                    v-model="cur_card_product.NonRevenue"
                    value="1"
                    unchecked-value="0"
                    switch
                    inline
                    style="float: right"
                    :disabled="disable_all"
                  >
                  </b-form-checkbox>
                </b-input-group-append>
              </b-input-group>
            </el-col>
          </el-row>
          <!-- <el-row :gutter="10">
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2 w-100">
                <b-input-group-prepend
                  :style="{ flex: '1' }"
                  class="group-append-text"
                  is-text
                >
                  {{ $t("Forced Binding VIP") + ":" }}
                </b-input-group-prepend>
                <b-input-group-append is-text>
                  <b-form-checkbox
                    class="form-checkbox"
                    v-model="cur_card_product.MustBindCustomer"
                    value="1"
                    unchecked-value="0"
                    switch
                    inline
                    style="float: right"
                    :disabled="disable_all || cur_card_product.IDn != 0 || vipcard_enable != 'Enable'"
                  >
                  </b-form-checkbox>
                </b-input-group-append>
              </b-input-group>
            </el-col>
            <el-col :span="6">
            </el-col>
            <el-col :span="6">
            </el-col>
            <el-col :span="6">
            </el-col>
          </el-row> -->
        </el-collapse-item>
        <el-collapse-item :title="$t('Product')" name="product" v-if="cur_card_product.Type === 'TimesCard' && hasUtilityAuth('CardEdit')">
          <el-table
            stripe
            class="table-responsive table card_table mt-2"
            style="width: 100%"
            header-row-class-name="thead-light"
            :data="cur_card_product.ProductList"
          >
            <el-table-column label="Name1" prop="Name1" class="p-0">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Name") }}</div>
              </template>
            </el-table-column>
            <el-table-column label="Count" prop="Count" class="p-0">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Count") }}</div>
              </template>
              <template v-slot="{ row }">
                {{ parseFloat(row.Count).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column label="Bonus" prop="Bonus" class="p-0">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Bonus") }}</div>
              </template>
              <template v-slot="{ row }">
                {{ parseFloat(row.Bonus).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column min-width="15%" label="">
              <template slot="header">
                <span class="btn-inner--icon">
                  <i class="ni ni-fat-add icon-add" @click="new_product">
                  </i>
                </span>
              </template>
              <template slot-scope="scope">
                <span class="btn-inner--icon" 
                  @click="remove_product(scope.row, scope.$index)">
                  <i class="ni ni-fat-remove icon-remove">
                  </i>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
        <el-collapse-item :title="$t('Store')" name="store" class="mt-2">
          <el-row :gutter="10">
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2 w-100">
                <b-input-group-prepend
                  :style="{ flex: '1' }"
                  class="group-append-text"
                  is-text
                >
                  {{ $t("All Stores") + ":" }}
                </b-input-group-prepend>
                <b-input-group-append is-text>
                  <b-form-checkbox
                    class="form-checkbox"
                    v-model="cur_card_product.StoresAllLocations"
                    value="1"
                    unchecked-value="0"
                    switch
                    inline
                    style="float: right"
                    :disabled="disable_all"
                  >
                  </b-form-checkbox>
                </b-input-group-append>
              </b-input-group>
            </el-col>
          </el-row>
          <el-row :gutter="10" v-if='cur_card_product.StoresAllLocations == 0'>
          <!-- <el-row :gutter="10"> -->
            <el-col :span="6" v-for="(location, idx) in cur_card_product.LocationList" :key="idx">
              <b-input-group class="mb-2 mt-2 w-100">
                <b-input-group-prepend
                  :style="{ flex: '1' }"
                  class="group-append-text"
                  is-text
                >
                  {{ location.LocationName + ":" }}
                </b-input-group-prepend>
                <b-input-group-append is-text>
                  <b-form-checkbox
                    class="form-checkbox"
                    v-model="location.card"
                    value="1"
                    unchecked-value="0"
                    switch
                    inline
                    style="float: right"
                    :disabled="disable_all"
                  >
                  </b-form-checkbox>
                </b-input-group-append>
              </b-input-group>
            </el-col>
          </el-row>
        </el-collapse-item>
        <el-collapse-item :title="$t('Card')" name="card" :disabled="cur_card_product.IsDynamic=='1'" class="mt-2">
          <div v-acl:CardEdit.enable.show v-if="cur_card_product.IsDynamic!='1'">
            <div style="float: left; padding: 10px">
              <xlsx-workbook>
                <xlsx-sheet
                  :collection="downloadsheet.data"
                  :sheet-name="downloadsheet.name"
                />
                <xlsx-download filename="card.xlsx" disable-wrapper-click>
                  <template #default="{ download }">
                    <el-button
                      type="primary"
                      icon="el-icon-download"
                      size="small"
                      @click="download"
                      >Download Import Template</el-button
                    >
                  </template>
                </xlsx-download>
              </xlsx-workbook>
            </div>
            <div style="float: left; padding: 10px">
              <input ref="fileuploader" type="file" @change="on_import_change" @click="reset_import_file"/>
              <xlsx-read :file="import_file" @parsed="import_file_parsed">
              </xlsx-read>
            </div>
          </div>
          <el-table
            v-if="cur_card_product.IsDynamic!='1'"
            stripe
            class="table-responsive table card_table mt-2"
            style="width: 100%"
            header-row-class-name="thead-light"
            :data="cur_card_product.CardListDisplay"
          >
            <el-table-column
              prop="Status"
            >
              <template v-slot:header>
                {{$t("Status")}}
                <div class="el-input el-input--mini">
                  <select v-model="card_search_status" @change="delay_search_card" class="el-input__inner">
                    <option
                      v-for="(cardstatus, idx) in card_status_list"
                      :key="idx"
                      :value="cardstatus">{{ cardstatus }}</option>
                  </select>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="CardNo"
            >
              <template v-slot:header>
                {{$t("CardNo")}}
                <el-input
                  v-model="card_search_cardno"
                  size="mini"
                  @keyup.native="delay_search_card"/>
              </template>
            </el-table-column>
            <el-table-column
              prop="SerialNumber"
              v-if="cur_card_product.EnableSerialNo == '1'"
            >
              <template v-slot:header>
                {{$t("Serial Number")}}
                <el-input
                  v-model="card_search_serialnumber"
                  size="mini"
                  @keyup.native="delay_search_card"/>
              </template>
            </el-table-column>
            <el-table-column
              prop="Balance"
              v-if="cur_card_product.Type == 'ValueCard'"
            >
              <template v-slot:header>
                {{$t("Balance")}}
                <el-input
                  v-model="card_search_balance"
                  size="mini"
                  @keyup.native="delay_search_card"/>
              </template>
              <template v-slot="{ row }">
                {{
                  '$' + parseFloat(row.Balance).toFixed(2)
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="Bonus"
              v-if="cur_card_product.Type == 'ValueCard'"
            >
              <template v-slot:header>
                {{$t("Bonus")}}
                <el-input
                  v-model="card_search_bonus"
                  size="mini"
                  @keyup.native="delay_search_card"/>
              </template>
              <template v-slot="{ row }">
                {{
                  '$' + parseFloat(row.Bonus).toFixed(2)
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="ActiveDateTime"
            >
              <template v-slot:header>
                {{$t("Active Date")}}
                <el-input
                  size="mini"
                  disabled/>
              </template>
              <template v-slot="{ row }">
                {{ row.ActiveDateTime ? row.ActiveDateTime.substr(0, 10) : '' }}
              </template>
            </el-table-column>
            <el-table-column min-width="50px" label="">
              <template slot="header">
                <span class="btn-inner--icon" v-if="hasUtilityAuth('CardEdit')">
                  <i class="ni ni-fat-add icon-add" @click="new_card">
                  </i>
                </span>
              </template>
              <template slot-scope="scope">
                <!-- TimesCard can view card -->
                <span class="btn-inner--icon" 
                  style="margin-right: 10px"
                  v-if="cur_card_product.Type == 'TimesCard'"
                  @click="view_card(scope.row, scope.$index)">
                  <i class="ni ni-single-copy-04 icon-view">
                  </i>
                </span>
                <!-- del card -->
                <span class="btn-inner--icon" v-if="hasUtilityAuth('CardEdit')"
                  @click="remove_card(scope.row, scope.$index)">
                  <i class="ni ni-fat-remove icon-remove">
                  </i>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>
    </div>

    <div v-if="curpage == 'wizard'">
      <div style="margin-top:30px" id="wizard">
        <div id="wizard_welcome">
          <el-row>
            <el-col>
              {{ $t("This wizard is intended to help you create card product step by step, please follow instructions below:") }}
              <hr align=center color=#cccccc style="margin:10px 0px">
            </el-col>
          </el-row>
        </div>
        <!-- <div id="wizard_vipcard" v-if="vipcard_enable == 'Enable'">
          <el-row>
            {{ $t("Is the card VIP card or gift card?") }}
            <br>
            {{ $t("VIP card is a card that must be bound with a VIP.") }}
            <br>
            {{ $t("Gift card is a card that can be used without being bound with a VIP.") }}
          </el-row>
          <el-row class="mt-2">
            <el-col :span="12">
              <el-radio v-model="wizard_card_product.MustBindCustomer" label="1">{{ $t("VIP Card") }}</el-radio>
            </el-col>
            <el-col :span="12">
              <el-radio v-model="wizard_card_product.MustBindCustomer" label="0">{{ $t("Gift Card") }}</el-radio>
            </el-col>
          </el-row>
          <hr align=center color=#cccccc style="margin:10px 0px">
        </div> -->
        <div id="wizard_valuecard">
          <el-row>
            {{ $t("Please select card type:") }}
          </el-row>
          <el-row class="mt-2">
            <el-col :span="12">
              <el-radio v-model="wizard_card_product.Type" label="ValueCard">{{ $t("Value Card") }}</el-radio>
            </el-col>
            <el-col :span="12">
              <el-radio v-model="wizard_card_product.Type" @change="wizard_refresh2" label="TimesCard">{{ $t("Times Card") }}</el-radio>
            </el-col>
          </el-row>
          <hr align=center color=#cccccc style="margin:10px 0px">
        </div>
        <div id="wizard_naming">
          <el-row>
            {{ $t("Please name this new card product:") }}
          </el-row>
          <el-row>
            <el-col :span="12">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Name') + '1:'">
                <b-input
                  class="form-control height_adjust_input left_padding"
                  v-model="wizard_card_product.Name1"
                />
              </b-input-group>
            </el-col>
            <el-col :span="12">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Name') + '2:'">
                <b-input
                  class="form-control height_adjust_input left_padding"
                  v-model="wizard_card_product.Name2"
                />
              </b-input-group>
            </el-col>
          </el-row>
          <hr align=center color=#cccccc style="margin:10px 0px">
        </div>
        <div id="wizard_department">
          <el-row>
            {{ $t("Please select department and category:") }}
          </el-row>
          <el-row>
            <el-col :span="12">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Department') + ':'">                  
                <el-select
                  class="el-dropdown4"
                  v-model="wizard_card_product.DepartmentIDn"
                >
                  <el-option
                    :value="0"
                    label=" - "
                    @click.native="changed_department_for_wizard(0)"
                  >
                  </el-option>
                  <el-option
                    v-for="(depart, idx) in department_list_for_wizard"
                    :key="idx"
                    :value="depart.IDn"
                    :label="depart.Name1 + ((show_name2 && depart.Name2) ? (' - ' + depart.Name2) : '')"
                    @click.native="changed_department_for_wizard(depart)"
                  >
                  </el-option>
                </el-select>
              </b-input-group>
            </el-col>
            <el-col :span="12">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Category') + ':'">                  
                <el-select
                  class="el-dropdown4"
                  v-model="wizard_card_product.CategoryIDn"
                >
                  <el-option
                    :value="0"
                    label=" - "
                  >
                  </el-option>
                  <el-option
                    v-for="(category, idx) in category_list_for_wizard"
                    :key="idx"
                    :value="category.IDn"
                    :label="category.Name1 + ((show_name2 && category.Name2) ? (' - ' + category.Name2) : '')"
                  >
                  </el-option>
                </el-select>
              </b-input-group>
            </el-col>
          </el-row>
          <hr align=center color=#cccccc style="margin:10px 0px">
        </div>
        <div id="wizard_price">
          <el-row>
            {{ $t("Please input sale price:") }}
          </el-row>
          <el-row>
            <el-col :span="12">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Price') + ':'">
                <b-input
                  v-model="wizard_card_product.APrice"
                  @keypress="onlyNumber"
                  @blur="handleBlur"
                />
              </b-input-group>
            </el-col>
          </el-row>
          <hr align=center color=#cccccc style="margin:10px 0px">
        </div>
        <div id="wizard_balance" v-if="wizard_card_product.Type == 'ValueCard'">
          <el-row>
            {{ $t("What's the balance when the card is being sold?") }}
          </el-row>
          <el-row>
            <el-col :span="12">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Default Balance') + ':'">
                <b-input
                  v-model="wizard_card_product.BPrice"
                  @keypress="onlyNumber"
                />
              </b-input-group>
            </el-col>
          </el-row>
          <hr align=center color=#cccccc style="margin:10px 0px">
        </div>
        <div id="wizard_bonus" v-if="wizard_card_product.Type == 'ValueCard'">
          <el-row>
            {{ $t("Will the card be sold with bonus?") }}
          </el-row>
          <el-row class="mt-2">
            <el-col :span="12">
              <el-radio v-model="wizard_card_product.has_bonus" @change="wizard_refresh" label="1">{{ $t("With bonus") }}</el-radio>
            </el-col>
            <el-col :span="12">
              <el-radio v-model="wizard_card_product.has_bonus" @change="wizard_refresh" label="0">{{ $t("Without bonus") }}</el-radio>
            </el-col>
          </el-row>
          <el-row v-if="wizard_card_product.has_bonus == '1'">
            <el-col :span="12">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Bonus') + ':'">
                <b-input
                  v-model="wizard_card_product.DPrice"
                  @keypress="onlyNumber"
                />
                <div class="input_percentage">%</div>
              </b-input-group>
            </el-col>
          </el-row>
          <hr align=center color=#cccccc style="margin:10px 0px">
        </div>
        <div id="wizard_chargemode" v-if="wizard_card_product.Type == 'ValueCard' && wizard_card_product.has_bonus == '1'">
          <el-row>
            {{ $t("How to deduct balance and bonus when use this card to pay?") }}
          </el-row>
          <el-row class="mt-2">
            <el-col :span="12">
              <el-radio v-model="wizard_card_product.ChargeMode" label="Regular">{{ $t("Deduct balance first, and deduct bonus after balance is 0") }}</el-radio>
            </el-col>
            <el-col :span="12">
              <el-radio v-model="wizard_card_product.ChargeMode" label="BonusEven">
                {{ $t("Deduct balance and bonus evenly") }}
                <br>
                {{ $t("e.g. There are $100 balance and $10 bonus in card, to pay $11, $10 from balance and $1 from bonus will be deducted.") }}
              </el-radio>
            </el-col>
          </el-row>
          <hr align=center color=#cccccc style="margin:10px 0px">
        </div>
        <div id="wizard_discount" v-if="wizard_card_product.Type == 'ValueCard' && wizard_card_product.ChargeMode != 'BonusEven'">
          <el-row>
            {{ $t("Will discount apply to order using this card to pay?") }}
          </el-row>
          <el-row class="mt-2">
            <el-col :span="12">
              <el-radio v-model="wizard_card_product.has_discount" @change="wizard_refresh" label="1">{{ $t("Apply discount") }}</el-radio>
            </el-col>
            <el-col :span="12">
              <el-radio v-model="wizard_card_product.has_discount" @change="wizard_refresh" label="0">{{ $t("No discount") }}</el-radio>
            </el-col>
          </el-row>
          <el-row v-if="wizard_card_product.has_discount == '1'">
            <el-col :span="12">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Discount Rate') + ':'">
                <b-input
                  v-model="wizard_card_product.Discount"
                  @keypress="onlyNumber"
                />
                <div class="input_percentage">%</div>
              </b-input-group>
            </el-col>
          </el-row>
          <hr align=center color=#cccccc style="margin:10px 0px">
        </div>
        <div id="wizard_reload">
          <el-row>
            {{ $t("Is the card reloadable?") }}
          </el-row>
          <el-row class="mt-2">
            <el-col :span="12">
              <el-radio v-model="wizard_card_product.CanReload" label="1">{{ $t("Reloadable") }}</el-radio>
            </el-col>
            <el-col :span="12">
              <el-radio v-model="wizard_card_product.CanReload" label="0">{{ $t("Non-Reloadable") }}</el-radio>
            </el-col>
          </el-row>
          <hr align=center color=#cccccc style="margin:10px 0px">
        </div>
        <div id="wizard_transfer">
          <el-row>
            {{ $t("Can the balance be transfered to another card?") }}
          </el-row>
          <el-row class="mt-2">
            <el-col :span="12">
              <el-radio v-model="wizard_card_product.CanTransfer" label="1">{{ $t("Can be transfered") }}</el-radio>
            </el-col>
            <el-col :span="12">
              <el-radio v-model="wizard_card_product.CanTransfer" label="0">{{ $t("Can't be transfered") }}</el-radio>
            </el-col>
          </el-row>
          <hr align=center color=#cccccc style="margin:10px 0px">
        </div>
        <div id="wizard_serialno">
          <el-row>
            {{ $t("In addition to the card number, does this card have a serial number?") }}
            <br>
            {{ $t("Note: Card number and serial number cannot be the same") }}
          </el-row>
          <el-row class="mt-2">
            <el-col :span="12">
              <el-radio v-model="wizard_card_product.EnableSerialNo" label="1">{{ $t("Has serial number") }}</el-radio>
            </el-col>
            <el-col :span="12">
              <el-radio v-model="wizard_card_product.EnableSerialNo" label="0">{{ $t("No serial number") }}</el-radio>
            </el-col>
          </el-row>
          <hr align=center color=#cccccc style="margin:10px 0px">
        </div>
        <div id="wizard_expire" v-if="wizard_card_product.Type == 'TimesCard'">
          <el-row>
            {{ $t("How long will the card expire after sold?") }}
          </el-row>
          <el-row>
            <el-col :span="12">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Expiry Period') + ':'">
                <el-row class="expire_input">
                  <el-col :span="16">
                    <b-input
                      v-model="wizard_card_product.ExpiryPeriodNum"
                      @keypress="onlyNumber"
                    />
                  </el-col>
                  <el-col :span="8">
                    <el-select
                      class="el-dropdown5"
                      v-model="wizard_card_product.ExpiryPeriodUnit"
                    >
                      <el-option
                        v-for="(unit, idx) in period_unit_list_show"
                        :key="idx"
                        :value="unit.value"
                        :label="unit.name"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                </el-row>
              </b-input-group>
            </el-col>
          </el-row>
          <hr align=center color=#cccccc style="margin:10px 0px">
        </div>
        <div id="wizard_taxtype">
          <el-row>
            {{ $t("Please select tax class:") }}
          </el-row>
          <el-row>
            <el-col :span="12">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Tax Class') + ':'">                  
                <el-select
                  class="el-dropdown4"
                  v-model="wizard_card_product.TaxTypeIDn"
                >
                  <el-option
                    v-for="(tax, idx) in tax_typelist"
                    :key="idx"
                    :value="tax.IDn"
                    :label="tax.Name"
                  >
                  </el-option>
                </el-select>
              </b-input-group>
            </el-col>
          </el-row>
          <hr align=center color=#cccccc style="margin:10px 0px">
        </div>
        <div id="wizard_revenue">
          <el-row>
            {{ $t("Is this card Non-Revenue?") }}
          </el-row>
          <el-row class="mt-2">
            <el-col :span="12">
              <el-radio v-model="wizard_card_product.NonRevenue" label="1">{{ $t("Non-Revenue") }}</el-radio>
            </el-col>
            <el-col :span="12">
              <el-radio v-model="wizard_card_product.NonRevenue" label="0">{{ $t("Revenue") }}</el-radio>
            </el-col>
          </el-row>
          <hr align=center color=#cccccc style="margin:10px 0px">
        </div>
        <div id="wizard_dynamic">
          <el-row>
            {{ $t("Is this card Can be created dynamically?") }}
          </el-row>
          <el-row class="mt-2">
            <el-col :span="12">
              <el-radio v-model="wizard_card_product.IsDynamic" label="1">{{ $t("Is Dynamic Create") }}</el-radio>
            </el-col>
            <el-col :span="12">
              <el-radio v-model="wizard_card_product.IsDynamic" label="0">{{ $t("Is not Dynamic Create") }}</el-radio>
            </el-col>
          </el-row>
          <hr align=center color=#cccccc style="margin:10px 0px">
        </div>
        <div id="wizard_product" v-if="wizard_card_product.Type == 'TimesCard'">
          <el-row>
            {{ $t("Please add products contained in the card:") }}
          </el-row>
          <el-row class="card">
            <el-table
              stripe
              class="table-responsive table card_table mt-2"
              style="width: 100%"
              header-row-class-name="thead-light"
              :data="wizard_card_product.ProductList"
            >
              <el-table-column label="Name1" prop="Name1" class="p-0">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Name") }}</div>
                </template>
              </el-table-column>
              <el-table-column label="Count" prop="Count" class="p-0">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Count") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{ parseFloat(row.Count).toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column label="Bonus" prop="Bonus" class="p-0">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Bonus") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{ parseFloat(row.Bonus).toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column min-width="15%" label="">
                <template slot="header">
                  <span class="btn-inner--icon">
                    <i class="ni ni-fat-add icon-add" @click="new_product">
                    </i>
                  </span>
                </template>
                <template slot-scope="scope">
                  <span class="btn-inner--icon" 
                    @click="remove_product(scope.row, scope.$index)">
                    <i class="ni ni-fat-remove icon-remove">
                    </i>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
          <hr align=center color=#cccccc style="margin:10px 0px">
        </div>
        <!-- hide if only one location in system, no need to query -->
        <div id="wizard_store" v-if="default_LocationList.length > 1">
          <el-row>
            {{ $t("Is this card universal in all stores?") }}
          </el-row>
          <el-row class="mt-2">
            <el-col :span="12">
              <el-radio v-model="wizard_card_product.StoresAllLocations" label="1">{{ $t("Universal in all stores") }}</el-radio>
            </el-col>
            <el-col :span="12">
              <el-radio v-model="wizard_card_product.StoresAllLocations" label="0">{{ $t("Only available in certain stores") }}</el-radio>
            </el-col>
          </el-row>
          <el-row v-if="wizard_card_product.StoresAllLocations == '0'">
            <el-col :span="12">
              <el-tree
                ref="tree"
                :props="{label: 'name', children: 'children'}"
                :data="wizard_card_product.vip_groups"
                show-checkbox
                :check-on-click-node="true"
                :default-expand-all="true">
              </el-tree>
            </el-col>
          </el-row>
          <hr align=center color=#cccccc style="margin:10px 0px">
        </div>

        <!------------------------------ wizard buttons -------------------------->
        <div class="text-right" style="margin-top: 10px; margin-bottom: 10px">
          <el-button type="primary" @click="card_wizard_confirm" v-acl:CardEdit.enable.show>
            {{ $t("Confirm") }}
          </el-button>
          <el-button @click="cancel_click">
            {{ $t("Back") }}
          </el-button>
        </div>
      </div>
    </div>

    <div v-if="curpage == 'wizard_success'">
      <div style="margin-top:30px">
        <div>
          <el-row>
            <el-col>
              {{ $t("Congratulations! You created a card product successfully!") }}
              <br>
              {{ $t("Next, you may want to set card numbers for this card product, please return to the card product list and edit your card product.") }}
            </el-col>
          </el-row>
        </div>
        <!------------------------------ wizard buttons -------------------------->
        <div class="text-right" style="margin-top: 10px; margin-bottom: 10px">
          <el-button type="primary" @click="cancel_click">
            {{ $t("Back") }}
          </el-button>
        </div>
      </div>
    </div>

    <div v-if="curpage == 'inquiry'">
      <div>
        <b-card>   <!-- query conditions -->
          <b-form>
            <el-row :gutter="10" style="margin-bottom: 10px">
              <el-col :span="6">
                <b-input-group :prepend="$t('CardNo') + ' : '">
                  <b-form-input v-model="inquiry.CardNo"></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="6">
                <b-input-group :prepend="$t('Serial Number') + ' : '">
                  <b-form-input v-model="inquiry.SerialNumber"></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="6">
                <b-input-group :prepend="$t('Status') + ' : '">
                  <select class="form-control" v-model="inquiry.Status">
                    <option value=""></option>
                    <option value="Active">{{ $t("Active") }}</option>
                    <option value="Inactive">{{ $t("Inactive") }}</option>
                  </select>
                </b-input-group>
              </el-col>
              <el-col :span="6">
                <b-input-group :prepend="$t('Type') + ' : '">
                  <select class="form-control" v-model="inquiry.Type">
                    <option value=""></option>
                    <option value="ValueCard">{{ $t("Value Card") }}</option>
                    <option value="TimesCard">{{ $t("Times Card") }}</option>
                  </select>
                </b-input-group>
              </el-col>
            </el-row>
            <el-row :gutter="10" style="margin-bottom: 10px">
              <el-col :span="6">
                <b-input-group :prepend="$t('Start Date')">
                  <flat-pickr
                    :config="picker_config2"
                    class="form-control datepicker"
                    v-model="inquiry.start_dt"
                  >
                  </flat-pickr>
                </b-input-group>
              </el-col>
              <el-col :span="6">
                <b-input-group :prepend="$t('End Date')">
                  <flat-pickr
                    :config="picker_config2"
                    class="form-control datepicker"
                    v-model="inquiry.end_dt"
                  >
                  </flat-pickr>
                </b-input-group>
              </el-col>
              <el-col :span="6">
                <b-input-group :prepend="$t('Date Type') + ' : '">
                  <select class="form-control" v-model="inquiry.date_type">
                    <option value=""></option>
                    <option value="activedate">{{ $t("Active Date") }}</option>
                  </select>
                </b-input-group>
              </el-col>
              <el-col :span="6">
                <el-button
                  type="primary"
                  class="mt-2"
                  icon="el-icon-search"
                  size="small"
                  @click="inquiry_search_card"
                  >{{ $t("Search") }}</el-button
                >
                <el-button
                  type="primary"
                  class="mt-2"
                  icon="el-icon-refresh-right"
                  size="small"
                  @click="inquiry_reset_search"
                  >{{ $t("Reset") }}</el-button
                >
                <el-button
                  type="primary"
                  class="mt-2"
                  icon="el-icon-back"
                  size="small"
                  @click="cancel_click"
                  >{{ $t("Back") }}</el-button
                >
              </el-col>
            </el-row>
          </b-form>
        </b-card>
        <CardList
          ref="cardlist"
          :inquiry_cards="inquiry_cards">
        </CardList>
      </div>
    </div>

    <!-- product modal -->
    <b-modal ref="product_modal" :title="$t('Add')" id="product_modal">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-cascader
            :props="{ expandTrigger: 'hover', emitPath: false }"
            :placeholder="$t('Try searching: product')"
            :options="cascader_options"
            :show-all-levels="false"
            v-model="times_product"
            clearable
            filterable
          ></el-cascader>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <b-input-group class="mb-2 mt-2" :prepend="$t('Count') + ':'">
            <b-input
              class="form-control height_adjust_input left_padding"
              v-model="times_product_count"
              @keypress="onlyNumber"
            />
          </b-input-group>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <b-input-group class="mb-2 mt-2" :prepend="$t('Bonus') + ':'">
            <b-input
              class="form-control height_adjust_input left_padding"
              v-model="times_product_bonus"
              @keypress="onlyNumber"
            />
          </b-input-group>
        </el-col>
      </el-row>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-primary"
          @click="add_product_confirm"
        >
          {{ $t('Confirm') }}
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          @click="close_product_modal"
        >
          {{ $t('Close') }}
        </button>
      </template>
    </b-modal>
    
    <!-- card modal -->
    <b-modal ref="card_modal" :title="$t(card_modal_title)" :size="card_modal_size" id="card_modal">
      <el-row :gutter="10">
        <el-col :span="24">
          <b-input-group class="mb-2 mt-2" :prepend="$t('CardNo') + ':'">
            <b-input
              class="form-control height_adjust_input left_padding"
              v-model="card_no"
            />
          </b-input-group>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="cur_card_product.EnableSerialNo == '1'">
        <el-col :span="24">
          <b-input-group class="mb-2 mt-2" :prepend="$t('Serial Number') + ':'">
            <b-input
              class="form-control height_adjust_input left_padding"
              v-model="serial_number"
            />
          </b-input-group>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="cur_card_product.Type == 'ValueCard'">
        <el-col :span="24">
          <b-input-group class="mb-2 mt-2" :prepend="$t('Balance') + ':'">
            <b-input
              class="form-control height_adjust_input left_padding"
              v-model="balance"
              @keypress="onlyNumber"
            />
          </b-input-group>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="cur_card_product.Type == 'ValueCard'">
        <el-col :span="24">
          <b-input-group class="mb-2 mt-2" :prepend="$t('Bonus') + ':'">
            <b-input
              class="form-control height_adjust_input left_padding"
              v-model="bonus"
              @keypress="onlyNumber"
            />
          </b-input-group>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="cur_card_product.Type != 'ValueCard' && card_model_mode == 'view'">
        <el-table
          stripe
          class="table-responsive table card_table mt-2"
          style="width: 100%"
          header-row-class-name="thead-light"
          :span-method="tc_detail_span_method"
          :data="productlist"
          max-height="430px"
        >
          <!-- package -->
          <el-table-column label="Status" prop="Status" class="p-0">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Status") }}</div>
            </template>
          </el-table-column>
          <el-table-column label="ExpireDateTime" prop="ExpireDateTime" class="p-0">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Expire Date") }}</div>
            </template>
            <template v-slot="{ row }">
              {{ row.ExpireDateTime ? row.ExpireDateTime.substr(0, 10) : '' }}
            </template>
          </el-table-column>
          <!-- product -->
          <el-table-column label="Name1" prop="Name1" class="p-0">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Name") }}</div>
            </template>
          </el-table-column>
          <el-table-column label="Count" prop="Count" class="p-0">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Count") }}</div>
            </template>
            <template v-slot="{ row }">
              {{ parseFloat(row.Count).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="Bonus" prop="Bonus" class="p-0">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Bonus") }}</div>
            </template>
            <template v-slot="{ row }">
              {{ parseFloat(row.Bonus).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="Price" prop="Price" class="p-0">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Price") }}</div>
            </template>
            <template v-slot="{ row }">
              {{ parseFloat(row.Price).toFixed(2) }}
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-primary"
          @click="add_card_confirm"
          v-if="card_model_mode != 'view'"
        >
          {{ $t('Confirm') }}
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          @click="close_card_modal"
        >
          {{ $t('Close') }}
        </button>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
import {
  Card,
  Table,
  TableColumn,
  Button,
  Pagination,
  Collapse,
  CollapseItem,
  Row,
  Col,
  Tabs,
  TabPane,
  Autocomplete,
  Radio,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Select,
  Option,
  Cascader,
  Tree,
} from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import { XlsxRead, XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import { hasUtilityAuth } from "../../directives/acl";
import CardList from "./CardList";

const axios = require("axios");

export default {
  components: {
    [Card.name]: Card,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [Pagination.name]: Pagination,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Select.name]: Select,
    [Option.name]: Option,
    [Autocomplete.name]: Autocomplete,
    [Radio.name]: Radio,
    [flatPicker.name]: flatPicker,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Cascader.name]: Cascader,
    [Tree.name]: Tree,
    XlsxRead,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    CardList,
  },
  watch: {
    current_page: function (newval, oldval) {
      this.get_lists();
    },
  },
  data() {
    return {
      token: "",
      user_id: "",
      current_page: 1,
      page_total: 0,
      per_page: 20,
      per_page_option: [10, 20, 50],

      delay_timer: null,
      card_delay_timer: null,
      curpage: 'list',
      activeCollapse1: ['base', 'store', 'card', 'product'],

      // Search condition
      cur_start_dt: "",
      cur_end_dt: "",
      cur_order_no: "",
      cur_transaction_no: "",
      cur_comment: "",
      cur_product_name4: "",
      cur_LocationID: "",

      // search card
      card_search_status: "",
      card_search_cardno: "",
      card_search_serialnumber: "",
      card_search_balance: "",
      card_search_bonus: "",

      show_name2: false,
      card_type_list: [{value:'ValueCard', label:'Value Card'}, {value:'TimesCard', label:'Times Card'}],
      charge_mode_list: [{value:'Regular', label:'Regular'}, {value:'BonusEven', label:'Deduct bonus evenly'}],
      period_unit_list: ['D', 'M', 'Y'],
      period_unit_list_show: [{value:'D', name:this.$t('Day')}, {value:'M', name:this.$t('Month')}, {value:'Y', name:this.$t('Year')}],
      card_status_list: ['Active', 'Inactive'],
      department_list: [],
      category_list: [],
      tax_typelist: [],
      card_product_list: [],
      cascader_options: [],
      card_product_meta: {},
      cur_card_product: {},
      vipcard_enable: '',
      default_LocationList: [],
      default_card_product: {
        IDn: 0,
        ProductIDn: 0,
        Name1: '',
        Name2: '',
        Status: 'Active',
        Type: 'ValueCard',
        CardType: 'Value',
        DepartmentIDn: 0,
        ChargeMode: 'Regular',
        CanTransfer: 0,
        CanReload: 0,
        Discount: 0,
        ExpiryPeriod: '',
        ExpiryPeriodNum: 0,
        ExpiryPeriodUnit: 'D',
        MustBindCustomer: 0,
        CategoryIDn: 0,
        Barcode: '',
        PLU: '',
        APrice: 0,
        BPrice: 0,
        CPrice: 0,
        DPrice: 0,
        TaxTypeIDn: '6',
        EnableSerialNo: '0',
        NonRevenue: '1',
        IsDynamic: '0',
        StoresAllLocations: 1,   // extra
        LocationList: [],   // extra   // should add field : card
        CardList: [],   // extra
        CardListDisplay: [],   // extra
        ProductList: [],   // extra
      },

      // card model
      card_model_mode: '',
      card_no: '',
      serial_number: '',
      balance: 0.0,
      bonus: 0.0,
      productlist: [],
      span_info: [],
      card_modal_title: 'Add',
      card_modal_size: 'sm',

      // product model
      times_product: null,
      times_product_count: 0,
      times_product_bonus: 0,

      freeze_button: false,
      disable_all: false,
      disable_discount: false,
      disable_balance: false,
      disable_bonus: false,
      disable_charge_mode: false,
      disable_transfer: false,
      disable_expiry_period: false,

      // import
      import_file: null,
      import_file_warning: "",
      downloadsheet: {
        name: "card",
        data: [["CardNo"]],
      },
      downloadsheet_nosn: {
        name: "card",
        data: [["CardNo", "Balance", "Bonus"]],
      },
      downloadsheet_sn: {
        name: "card",
        data: [["CardNo", "SerialNumber", "Balance", "Bonus"]],
      },
      downloadsheet_tc_nosn: {
        name: "card",
        data: [["CardNo"]],
      },
      downloadsheet_tc_sn: {
        name: "card",
        data: [["CardNo", "SerialNumber"]],
      },

      // wizard
      wizard_card_product: null,
      department_list_for_wizard: [],
      category_list_for_wizard: [],

      // card inquiry
      inquiry: null,
      inquiry_default: {CardNo:'',
                        SerialNumber:'',
                        Status:'Active',
                        Type:'',
                        start_dt:'',
                        end_dt:'',
                        date_type:'activedate',
                        },
      inquiry_cards: [],
      picker_config2: {
        allowInput: true,
        dateFormat: "Y-m-d",
        altFormat: "Y-m-d",
        maxDate: new Date().fp_incr(6), // New Date();  YYYY-MM-DD HH:MM
      },
    };
  },
  computed: {
    apiBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
  },
  methods: {
    hasUtilityAuth,
    async get_lists() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/POS/Card/list",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.card_product_list = rt.data.list_list;
              that.card_product_meta = rt.data.list_meta;
              if (that.card_product_meta.cnt != undefined) {
                that.page_total = that.card_product_meta.cnt;
                that.per_page = rt.data.per_page;
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            this.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_init_data() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/POS/Card",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.card_product_list = rt.data.list_list;
              that.card_product_meta = rt.data.list_meta;
              if (that.card_product_meta.cnt != undefined) {
                that.page_total = that.card_product_meta.cnt;
                that.per_page = rt.data.per_page;
              }
              that.show_name2 = rt.data.show_name2;
              that.department_list = rt.data.departments;
              that.department_list_for_wizard = rt.data.departments;
              that.tax_typelist = rt.data.tax_types;
              that.default_LocationList = rt.data.default_LocationList;
              that.vip_groups = rt.data.vip_groups;
              that.cascader_options = rt.data.cascader_options;
              that.vipcard_enable = rt.data.vipcard_enable;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_extra() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("card_product_id", this.cur_card_product.IDn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/POS/Card/extra",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_card_product.CardList = rt.data.cards;
              that.cur_card_product.CardListDisplay = that.cur_card_product.CardList;
              that.$forceUpdate();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    changed_department(depart) {
      if (depart == 0) {
        this.category_list = [];
        this.cur_card_product.CategoryIDn = 0;
      }
      this.category_list = depart.Categorys;
      this.cur_card_product.CategoryIDn = 0;
    },
    onchange_charge_mode() {
      this.check_fields_relations();
    },
    onchange_type() {
      this.check_fields_relations();
    },
    check_fields_relations() {
      this.disable_discount = (this.cur_card_product.ChargeMode === 'BonusEven' || this.cur_card_product.Type === 'TimesCard');
      this.cur_card_product.Discount = this.disable_discount ? 0 : this.cur_card_product.Discount;

      // if timescard, disable charge_mode
      this.disable_charge_mode = (this.cur_card_product.Type === 'TimesCard');
      this.cur_card_product.ChargeMode = this.disable_charge_mode ? 'Regular' : this.cur_card_product.ChargeMode;
      
      this.cur_card_product.CanTransfer = this.disable_transfer ? '0' : this.cur_card_product.CanTransfer;

      // if timescard, disable balance
      this.disable_balance = (this.cur_card_product.Type === 'TimesCard');
      this.cur_card_product.Balance = this.disable_balance ? 0 : this.cur_card_product.Balance;

      // if timescard, disable bonus
      this.disable_bonus = (this.cur_card_product.Type === 'TimesCard');
      this.cur_card_product.Bonus = this.disable_bonus ? 0 : this.cur_card_product.Bonus;

      // if valuecard, disable expiryperiod
      this.disable_expiry_period = (this.cur_card_product.Type === 'ValueCard');
      this.cur_card_product.ExpiryPeriodNum = this.disable_expiry_period ? 0 : this.cur_card_product.ExpiryPeriodNum;
      this.cur_card_product.ExpiryPeriodUnit = this.disable_expiry_period ? 'D' : this.cur_card_product.ExpiryPeriodUnit;

      this.calculate_el_select_width2();
    },
    new_card_product() {
      if (!hasUtilityAuth('CardEdit')) {
        return;
      }

      this.freeze_button = false;
      this.cur_card_product = JSON.parse(JSON.stringify(this.default_card_product));
      this.cur_card_product.LocationList = this.default_LocationList;
      this.card_search_status = "";
      this.card_search_cardno = "";
      this.card_search_serialnumber = "";
      this.card_search_balance = "";
      this.card_search_bonus = "";
      this.check_fields_relations();
      this.curpage = 'edit';
      this.calculate_el_select_width2();
    },
    card_wizard() {
      this.wizard_card_product = JSON.parse(JSON.stringify(this.default_card_product));
      this.wizard_card_product.CanTransfer = '1';
      this.wizard_card_product.CanReload = '1';
      this.wizard_card_product.MustBindCustomer = '0';
      this.wizard_card_product.EnableSerialNo = '0';
      this.wizard_card_product.NonRevenue = '1';
      this.wizard_card_product.IsDynamic = '0';
      this.wizard_card_product.StoresAllLocations = '1';
      this.wizard_card_product.LocationList = JSON.parse(JSON.stringify(this.default_LocationList));
      // extra fields
      this.wizard_card_product.has_bonus = '0';
      this.wizard_card_product.has_discount = '0';
      let vip_groups = JSON.parse(JSON.stringify(this.vip_groups));
      vip_groups.push({IDn:0, VIPGroupName:'Default'});
      this.wizard_card_product.vip_groups = [];
      for (var i = 0; i < vip_groups.length; i++) {
        let group = vip_groups[i];
        group.children = [];
        group.name = group.VIPGroupName;
        for (var j = 0; j < this.wizard_card_product.LocationList.length; j++) {
          let location = this.wizard_card_product.LocationList[j];
          if (group.IDn == location.VIPGroupIDn) {
            location.name = location.LocationName;
            group.children.push(location);
          }
        }
        if (group.children.length != 0) {
          this.wizard_card_product.vip_groups.push(group);
        }
      }

      this.curpage = 'wizard';
      this.calculate_el_select_width2();
    },
    card_wizard_confirm() {
      this.wizard_card_product.ExpiryPeriodNum = parseInt(this.wizard_card_product.ExpiryPeriodNum);
      if (isNaN(this.wizard_card_product.ExpiryPeriodNum)) {
        this.wizard_card_product.ExpiryPeriodNum = 0;
      }
      this.wizard_card_product.ExpiryPeriod = this.wizard_card_product.ExpiryPeriodNum + this.wizard_card_product.ExpiryPeriodUnit;
      
      if (this.wizard_card_product.ChargeMode == 'BonusEven') {
        this.wizard_card_product.Discount = 0;
      }

      if (!this.wizard_card_product.Name1) {
        alert("Name1 can't be empty");
        return;
      }
      if (!this.wizard_card_product.DepartmentIDn) {
        alert("Department can't be empty");
        return;
      }
      if (!this.wizard_card_product.CategoryIDn) {
        alert("Category can't be empty");
        return;
      }
      if (!this.wizard_card_product.TaxTypeIDn) {
        alert("Tax Type can't be empty");
        return;
      }
      if (this.wizard_card_product.Type === 'TimesCard' && this.wizard_card_product.ProductList.length == 0) {
        alert("Times card should contain one product at least");
        return;
      }

      if (!this.check_taxtype(this.wizard_card_product)) {
        alert(this.$t("Times card product and products of its package must have the same Tax Class"));
        return;
      }

      if (this.$refs.tree) {
        let checked_leaf_nodes_locationids = this.$refs.tree.getCheckedNodes(true).map(a => a.LocationID);
        for (var j = 0; j < this.wizard_card_product.LocationList.length; j++) {
          let location = this.wizard_card_product.LocationList[j];
          if (checked_leaf_nodes_locationids.includes(location.LocationID)) {
            location.card = 1;
          } else {
            location.card = 0;
          }
        }
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("card_product", JSON.stringify(this.wizard_card_product));
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/POS/Card/save",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.card_product_list = rt.data.list_list;
              that.card_product_meta = rt.data.list_meta;
              if (that.card_product_meta.cnt != undefined) {
                that.page_total = that.card_product_meta.cnt;
                that.per_page = rt.data.per_page;
              }
              that.curpage = "wizard_success";
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    handleBlur() {
      this.wizard_card_product.BPrice = this.wizard_card_product.APrice;
    },
    changed_department_for_wizard(depart) {
      if (depart == 0) {
        this.category_list_for_wizard = [];
        this.wizard_card_product.CategoryIDn = 0;
      }
      this.category_list_for_wizard = depart.Categorys;
      this.wizard_card_product.CategoryIDn = 0;
    },
    wizard_refresh() {
      this.$forceUpdate();
    },
    wizard_refresh2() {
      this.calculate_el_select_width2();
    },
    card_inquiry() {
      this.inquiry = JSON.parse(JSON.stringify(this.inquiry_default));
      this.inquiry_cards = [];
      this.curpage = 'inquiry';
    },
    inquiry_search_card() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("inquiry", JSON.stringify(this.inquiry));

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/POS/Card/inquiry",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.inquiry_cards = rt.data.cards;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    inquiry_reset_search() {
      this.inquiry = JSON.parse(JSON.stringify(this.inquiry_default));
      this.inquiry_cards = [];
    },
    edit_card_product(row, event, column) {
      this.freeze_button = false;
      this.disable_all = !hasUtilityAuth('CardEdit');

      // get category
      for (var i = 0; i < this.department_list.length; i++) {
        if (row.DepartmentIDn == this.department_list[i].IDn) {
          this.category_list = this.department_list[i].Categorys;
          break;
        }
      }

      if (typeof row.ExpiryPeriod === 'string'
          && row.ExpiryPeriod != '' 
          && this.period_unit_list.includes(row.ExpiryPeriod.substr(-1))) {
        row.ExpiryPeriodNum = row.ExpiryPeriod.substring(0, row.ExpiryPeriod.length - 1);
        row.ExpiryPeriodUnit = row.ExpiryPeriod.substr(-1);
      } else {
        row.ExpiryPeriodNum = 0;
        row.ExpiryPeriodUnit = 'D';
      }

      this.cur_card_product = JSON.parse(JSON.stringify(row));
      if (this.cur_card_product.Type === 'TimesCard') {
        this.downloadsheet = this.cur_card_product.EnableSerialNo == '1' ? this.downloadsheet_tc_sn : this.downloadsheet_tc_nosn;
      } else {
        this.downloadsheet = this.cur_card_product.EnableSerialNo == '1' ? this.downloadsheet_sn : this.downloadsheet_nosn;
      }

      this.cur_card_product.APrice = parseFloat(this.cur_card_product.APrice).toFixed(2);
      this.cur_card_product.BPrice = parseFloat(this.cur_card_product.BPrice).toFixed(2);
      this.cur_card_product.DPrice = parseFloat(this.cur_card_product.DPrice).toFixed(2);
      this.cur_card_product.Discount = parseFloat(this.cur_card_product.Discount).toFixed(2);
      this.get_extra();
      this.card_search_status = "";
      this.card_search_cardno = "";
      this.card_search_serialnumber = "";
      this.card_search_balance = "";
      this.card_search_bonus = "";
      this.check_fields_relations();
      this.curpage = 'edit';
      window.scrollTo(0,0);
      this.calculate_el_select_width2();
      // console.log(this.cur_card_product.CardListDisplay);
      // this.search_card();
    },
    tc_detail_span_method({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1) {
        for (var i = 0; i < this.span_info.length; i++) {
          if (rowIndex == this.span_info[i][0]) {
            return {
              rowspan: this.span_info[i][1],
              colspan: 1
            };
          }
        }
        return {
          rowspan: 0,
          colspan: 0
        };
      }
    },
    view_card(card, index) {
      this.card_model_mode = 'view';
      this.card_no = card.CardNo;
      this.serial_number = card.SerialNumber;
      this.productlist = card.ProductList;
      this.span_info = card.span_info;
      this.card_modal_title = 'Detail';
      this.card_modal_size = 'xl';
      this.$refs["card_modal"].show();
    },
    new_card() {
      if (this.cur_card_product.IDn == 0 || this.cur_card_product.ProductIDn == 0) {
        alert("Please save first!");
        return;
      }

      this.card_model_mode = 'new';
      this.card_no = '';
      this.serial_number = '';
      this.balance = this.cur_card_product.BPrice;
      let bonus = parseFloat(this.cur_card_product.DPrice);
      if (isNaN(bonus)) bonus = 0;
      let balance = parseFloat(this.cur_card_product.BPrice);
      if (isNaN(balance)) balance = 0;
      this.bonus = balance * bonus / 100;
      this.card_modal_title = 'Add';
      this.card_modal_size = 'sm';
      this.$refs["card_modal"].show();
    },
    add_card_confirm() {
      var sn = parseInt(this.cur_card_product.EnableSerialNo);

      if (!this.card_no) {
        alert("Card Number can't be empty");
        return;
      }
      if (sn && !this.serial_number) {
        alert("Serial Number can't be empty");
        return;
      }

      this.balance = parseFloat(this.balance);
      if (isNaN(this.balance)) {
        alert("Invalid Balance!");
        this.balance = this.cur_card_product.BPrice;
        return;
      } else {
        this.balance = this.balance.toFixed(2);
      }
      if (parseFloat(this.balance) < 0) {
        alert("Balance can't be less than 0!");
        return;
      }

      this.bonus = parseFloat(this.bonus);
      if (isNaN(this.bonus)) {
        alert("Invalid Bonus!");
        this.bonus = this.cur_card_product.DPrice;
        return;
      } else {
        this.bonus = this.bonus.toFixed(2);
      }
      if (parseFloat(this.bonus) < 0) {
        alert("Bonus can't be less than 0!");
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      bodyFormData.append("CardNo", this.card_no);
      if (sn) {
        bodyFormData.append("SerialNumber", this.serial_number);
      }
      bodyFormData.append("Balance", this.balance);
      bodyFormData.append("Bonus", this.bonus);
      bodyFormData.append("CardProductIDn", this.cur_card_product.IDn);
      bodyFormData.append("ProductIDn", this.cur_card_product.ProductIDn);
      bodyFormData.append("sn", sn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/POS/Card/add_card",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_card_product.CardList = rt.data.cards;
              that.search_card();
              that.$refs["card_modal"].hide();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    remove_card(row, index) {
      var alertMsg = "Are you sure to delete this card?";
      var r = confirm(alertMsg);
      if (r == false) {
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("card_id", row.IDn);
      bodyFormData.append("card_product_id", this.cur_card_product.IDn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/POS/Card/remove_card",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_card_product.CardList = rt.data.cards;
              that.search_card();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    new_product() {
      this.times_product = null;
      this.times_product_count = 0;
      this.times_product_bonus = 0;
      this.$refs["product_modal"].show();
    },
    add_product_confirm() {
      if (!this.times_product) {
        alert('No product is selected');
        return;
      }

      if (this.times_product_count == 0 && this.times_product_bonus == 0) {
        alert('At least set Count or Bonus');
        return;
      }

      let tmp = {ProdID: this.times_product.IDn, Name1: this.times_product.Name1, Count: this.times_product_count, Bonus: this.times_product_bonus};
      if (this.curpage == 'wizard') {
        this.wizard_card_product.ProductList.push(tmp);
      } else {
        this.cur_card_product.ProductList.push(tmp);
      }
      this.$refs["product_modal"].hide();
    },
    close_product_modal() {
      this.$refs["product_modal"].hide();
    },
    remove_product(row, index) {
      this.cur_card_product.ProductList.splice(index, 1);
    },
    delay_search_card() {
      if (this.card_delay_timer) {
        clearTimeout(this.card_delay_timer);
        this.card_delay_timer = null;
      }
      var that = this;
      this.card_delay_timer = setTimeout(() => {
        that.search_card();
      }, 400);
    },
	  search_card() {
      if (this.card_search_status == ""
          && this.card_search_cardno == ""
          && this.card_search_serialnumber == ""
          && this.card_search_balance == ""
          && this.card_search_bonus == "") {
        this.cur_card_product.CardListDisplay = this.cur_card_product.CardList;
        this.$forceUpdate();
        return;
      }
      this.cur_card_product.CardListDisplay = [];
      for (var i = 0; i < this.cur_card_product.CardList.length; i++) {
        if (this.matching_card(this.cur_card_product.CardList[i])) {
          this.cur_card_product.CardListDisplay.push(this.cur_card_product.CardList[i]);
        }
      }
      this.$forceUpdate();
    },
    matching_card(item) {
      if (
          (!item.Status
          || item.Status.toString().search(this.card_search_status) >= 0)
          &&
          (!item.CardNo
          || item.CardNo.toString().search(this.card_search_cardno) >= 0)
          &&
          (!item.SerialNumber
          || item.SerialNumber.toString().search(this.card_search_serialnumber) >= 0)
          &&
          (!item.Balance
          || item.Balance.toString().search(this.card_search_balance) >= 0)
          &&
          (!item.Bonus
          || item.Bonus.toString().search(this.card_search_bonus) >= 0)
          ) {
        return true;
      }
      return false;
    },
    handleCommand(selected) {
      this.per_page = selected;
      this.current_page=1;
      this.get_lists();
    },
    cancel_click() {
      this.curpage = "list";
    },
    check_taxtype(product) {
      if (product.ProductList && product.ProductList.length > 0) {
        // check each tc product
        for (let i = 0; i < product.ProductList.length; i++) {
          // get product by tc product idn
          for (var j = 0; j < this.cascader_options.length; j++) {
            for (var k = 0; k < this.cascader_options[j].children.length; k++) {
              for (var l = 0; l < this.cascader_options[j].children[k].children.length; l++) {
                let prod = this.cascader_options[j].children[k].children[l].value;
                if (product.ProductList[i].ProdID == prod.IDn) {
                  return prod.TaxTypeIDn == product.TaxTypeIDn;
                }
              }
            }
          }
        }
      }
      return true;
    },
    save() {
      this.cur_card_product.ExpiryPeriodNum = parseInt(this.cur_card_product.ExpiryPeriodNum);
      if (isNaN(this.cur_card_product.ExpiryPeriodNum)) {
        this.cur_card_product.ExpiryPeriodNum = 0;
      }
      this.cur_card_product.ExpiryPeriod = this.cur_card_product.ExpiryPeriodNum + this.cur_card_product.ExpiryPeriodUnit;

      if (!this.cur_card_product.Name1) {
        alert("Name1 can't be empty");
        return;
      }
      if (!this.cur_card_product.DepartmentIDn) {
        alert("Department can't be empty");
        return;
      }
      if (!this.cur_card_product.CategoryIDn) {
        alert("Category can't be empty");
        return;
      }
      if (!this.cur_card_product.TaxTypeIDn) {
        alert("Tax Type can't be empty");
        return;
      }
      if (this.cur_card_product.Type === 'TimesCard' && this.cur_card_product.ProductList.length == 0) {
        alert("Times card should contain one product at least");
        return;
      }

      if (!this.check_taxtype(this.cur_card_product)) {
        alert(this.$t("Times card product and products of its package must have the same Tax Class"));
        return;
      }

      this.freeze_button = true;
      
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      var gcp = JSON.parse(JSON.stringify(this.cur_card_product));
      gcp.CardListDisplay = null;
      gcp.CardList = null;
      bodyFormData.append("card_product", JSON.stringify(gcp));
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/POS/Card/save",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.card_product_list = rt.data.list_list;
              that.card_product_meta = rt.data.list_meta;
              if (that.card_product_meta.cnt != undefined) {
                that.page_total = that.card_product_meta.cnt;
                that.per_page = rt.data.per_page;
              }
              that.curpage = "list";
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
              that.freeze_button = false;
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    close_card_modal() {
      this.$refs["card_modal"].hide();
    },
    onlyNumber (evt) {
      // console.log("onlyNumber");
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    calculate_el_select_width2(){
      setTimeout(()=>{
        for(var i = 0; i < document.getElementsByClassName("el-dropdown4").length; i++){
          document.getElementsByClassName("el-dropdown4")[i].children[0].children[0].style.borderRadius = "0 0.375rem 0.375rem 0";
          document.getElementsByClassName("el-dropdown4")[i].style.width = (parseFloat(document.getElementsByClassName("height_adjust_input")[0].clientWidth)) + "px";
          document.getElementsByClassName("el-dropdown4")[i].children[0].children[0].style.height = "46px";
        }
        for(var i = 0; i < document.getElementsByClassName("el-dropdown5").length; i++){
          document.getElementsByClassName("el-dropdown5")[i].children[0].children[0].style.borderRadius = "0 0.375rem 0.375rem 0";
          document.getElementsByClassName("el-dropdown5")[i].style.width = (parseFloat(document.getElementsByClassName("height_adjust_input")[0].clientWidth) / 3) + "px";
          document.getElementsByClassName("el-dropdown5")[i].children[0].children[0].style.height = "46px";
        }
        for(var i = 0; i < document.getElementsByClassName("expire_input").length; i++){
          document.getElementsByClassName("expire_input")[i].children[0].children[0].style.borderRadius = "0";
          document.getElementsByClassName("expire_input")[i].style.width = (parseFloat(document.getElementsByClassName("height_adjust_input")[0].clientWidth)) + "px";
          document.getElementsByClassName("expire_input")[i].children[0].children[0].style.height = "46px";
        }
      },1);
    },
    on_import_change(event) {
      this.import_file = event.target.files ? event.target.files[0] : null;
    },
    reset_import_file() {
      this.import_file = null;
      this.$refs.fileuploader.value = '';
    },
    import_file_parsed(filedata) {
      this.reset_import_file();
      this.selected_product_list = [];
      var sn = parseInt(this.cur_card_product.EnableSerialNo);
      var tc = this.cur_card_product.Type === 'TimesCard';
      // console.log('import_file_parsed', arguments, filedata);
      var sheets = filedata.Sheets[Object.keys(filedata.Sheets)[0]];
      if (sn) {
        var a = sheets["A1"] != undefined ? sheets["A1"].w.trim() : "";    // CardNo
        var b = sheets["B1"] != undefined ? sheets["B1"].w.trim() : "";    // SerialNumber
        var c = sheets["C1"] != undefined ? sheets["C1"].w.trim() : "";    // Balance
        var d = sheets["D1"] != undefined ? sheets["D1"].w.trim() : "";    // Bonus
        if (a != "CardNo" || b != "SerialNumber" || (!tc && (c != "Balance" || d != "Bonus"))) {
          this.import_file_warning = "Wrong column";
          alert(this.import_file_warning);
          return;
        }
      } else {
        var a = sheets["A1"] != undefined ? sheets["A1"].w.trim() : "";    // CardNo
        var b = sheets["B1"] != undefined ? sheets["B1"].w.trim() : "";    // Balance
        var c = sheets["C1"] != undefined ? sheets["C1"].w.trim() : "";    // Bonus
        if (a != "CardNo" || (!tc && (c != "Bonus"))) {
          this.import_file_warning = "Wrong column";
          alert(this.import_file_warning);
          return;
        }
      }
      var import_data = [];
      for (var idx = 2; idx < 10000; idx++) {
        // Max 10000 for safe
        var cardno = sheets["A" + idx] != undefined ? sheets["A" + idx].w.trim() : "";
        if (sn) {
          var serialno = sheets["B" + idx] != undefined ? sheets["B" + idx].w.trim() : "";
          var balance = sheets["C" + idx] != undefined ? sheets["C" + idx].w.trim() : "";
          var bonus = sheets["D" + idx] != undefined ? sheets["D" + idx].w.trim() : "";
          if (!cardno || !serialno || (!tc && !balance)) {
            break;
          }
        } else {
          var balance = sheets["B" + idx] != undefined ? sheets["B" + idx].w.trim() : "";
          var bonus = sheets["C" + idx] != undefined ? sheets["C" + idx].w.trim() : "";
          if (!cardno || (!tc && !balance)) {
            break;
          }
        }

        if (sn && cardno == serialno) {
          this.import_file_warning = "CardNo can't be the same as SerialNumber at line " + idx;
          alert(this.import_file_warning);
          return;
        }

        // check cardno duplicated
        for (var i = 0; i < import_data.length; i++) {
          if (sn) {
            if (cardno == import_data[i].CardNo || cardno == import_data[i].SerialNumber || serialno == import_data[i].CardNo || serialno == import_data[i].SerialNumber) {
              this.import_file_warning = "CardNo or SerialNumber is duplicated at line " + idx;
              alert(this.import_file_warning);
              return;
            }
          } else {
            if (cardno == import_data[i].CardNo) {
              this.import_file_warning = "CardNo is duplicated at line " + idx;
              alert(this.import_file_warning);
              return;
            }
          }
        }

        if (tc) {
          // times card
          balance = 0;
          bonus = 0;
        } else {
          // value card
          balance = parseFloat(balance);  // balance
          if (isNaN(balance)) {
            this.import_file_warning = "Balance is not a number at line " + idx;
            alert(this.import_file_warning);
            return;
          }
          if (balance < 0) {
            this.import_file_warning = "Balance can't be less than 0 at line " + idx;
            alert(this.import_file_warning);
            return;
          }

          bonus = parseFloat(bonus);  // bonus
          if (isNaN(bonus) || bonus < 0) {
            bonus = 0;
          }
        }

        if (sn) {
          import_data.push({
            CardNo: cardno,
            SerialNumber: serialno,
            Balance: balance,
            Bonus: bonus,
          });
        } else {
          import_data.push({
            CardNo: cardno,
            Balance: balance,
            Bonus: bonus,
          });
        }
      }

      if (import_data.length > 0) {
        var bodyFormData = new FormData();
        bodyFormData.append("token", this.token);
        bodyFormData.append("user_id", this.user_id);

        bodyFormData.append("sn", sn);
        bodyFormData.append("cards", JSON.stringify(import_data));
        bodyFormData.append("CardProductIDn", this.cur_card_product.IDn);
        bodyFormData.append("ProductIDn", this.cur_card_product.ProductIDn);

        var that = this;
        axios({
          method: "post",
          url: "/Api/Web/POS/Card/add_multi_card",
          data: bodyFormData,
        })
          .then(function (response) {
            if (response.status == "200") {
              var rt = response.data;
              if (rt.status == 0) {
                that.cur_card_product.CardList = rt.data.cards;
                that.search_card();
                alert('Import success');
              } else if (rt.status == 4) {
                alert(that.$t("No Permission"));
                that.$router.go(-1);
              } else if (rt.message != undefined) {
                alert(rt.message);
              }
            } else {
              that.$router.push("/login");
            }
          })
          .catch(function (error) {
            // console.log(error);
            that.$router.push("/login");
          });
      } else {
          this.import_file_warning = "No data in excel file";
          alert(this.import_file_warning);
      }
    },
  },
  mounted() {
    this.token = getStore("token");
    this.user_id = getStore("user_id");
    if (!this.token || !this.user_id) {
      this.$router.push("/login");
      return;
    }
    this.get_init_data();
  },
};
</script>
<style>
.content {
  padding-top: 40px;
}
hr.solid {
  border-top: 1px solid #bbb;
}
.input-label {
  display: inline-block;
  width: 130px;
}

.el-collapse-item__header {
  background-color: #a5d1fa;
  font-size: 1.3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 2.2rem;
  border-radius: 0.75rem 0.75rem 0 0;
}
.el-collapse-item__content {
  padding-bottom: 0.5rem;
  border: 3px solid rgb(165, 209, 250);
}
.el-collapse-item__wrap {
  display: contents;
  content: "";
  clear: both;
  width: calc(100% - 5px);
  overflow-y: visible;
}

.product_popup {
  padding: 2%;
  position: fixed;
  top: calc(50% - 21rem);
  left: 15%;
  height: 42rem;
  width: 70%;
  z-index: 10;
  background: white;
}
.group-append-text .input-group-text {
  flex: 1;
}

.product-table th,
.product-table td {
  padding: 0.1rem !important;
}
.product-table th div,
.product-table td div {
  padding: 0.1rem !important;
  line-height: 1.25rem;
  overflow: visible;
}

#search_modal .modal-title,
#import_modal .modal-title {
  font-size: 1.5rem;
}
#search_modal hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.search_product_section {
  max-height: 12rem;
  overflow: auto;
}
.search_product_section .el-table td,
.el-table th {
  padding: 2px 0;
}
.product_row {
  border-top: 1px solid gray;
}
.product_sn {
  max-width: 10rem;
  display: inline-flex;
}
.product_sn_col {
  max-height: 4rem;
  overflow: auto;
}
.btn-inner--icon .icon-add {
  color: green;
}
.btn-inner--icon .icon-remove {
  color: red;
}
.order_title {
  font-weight: bold;
  font-size: 1.2rem;
}
.order_card_title {
  font-weight: 500;
  font-size: 1.2rem !important;
  padding: 0;
  line-height: inherit;
  color: inherit;
}
.so-edit .el-card__header {
  padding: 0.1rem 1rem;
}
.so-edit div {
  font-size: 1rem;
}
.so-edit .el-card__body {
  padding: 0.2rem;
}
.so-edit .icon-add {
  color: #404040;
  background-color: rgba(0, 255, 0, 0.5);
  border-radius: 50%;
}
.left_padding {
  padding-left: 15px !important;
}
.card_table .btn-inner--icon .ni:hover {
  cursor: pointer;
}
.card_table .btn-inner--icon .icon-add {
  color: #404040;
  background-color: rgba(0, 255, 0, 0.5);
  border-radius: 50%;
  font-size: 1.8rem;
}
.card_table .btn-inner--icon .icon-remove {
  color: red;
  font-size: 1.8rem;
  top: 4px;
}
.card_table .btn-inner--icon .icon-view {
  color: #0d7924;
  font-size: 1.2rem;
  top: 1px;
}
.card_table .btn-inner--icon .icon-edit {
  font-size: 1.4rem;
  margin-left: 20px;
}
.height_adjust_input {
  height: 46px;
}
.input_percentage {
  background-color: #F5F7FA;
  color: #909399;
  position: relative;
  border: 1px solid #DCDFE6;
  border-radius: 0 4px 4px 0;
  padding: 3px 20px;
  white-space: nowrap;
  font-size: 20px;
  font-weight: 600;
}
#wizard .el-radio__label {
  font-size: 18px;
}
</style>