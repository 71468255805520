<template>
  <div>
    <div>
      <b-card>
        <el-table
          stripe
          class="table-responsive table card_table mt-2"
          style="width: 100%"
          header-row-class-name="thead-light"
          :data="inquiry_cards"
        >
          <el-table-column
            prop="CardNo"
          >
            <template v-slot:header>
              {{$t("CardNo")}}
            </template>
          </el-table-column>
          <el-table-column
            prop="SerialNumber"
          >
            <template v-slot:header>
              {{$t("Serial Number")}}
            </template>
          </el-table-column>
          <el-table-column
            prop="Type"
          >
            <template v-slot:header>
              {{$t("Type")}}
            </template>
            <template v-slot="{ row }">
              {{
                $t(row.Type)
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="Balance"
          >
            <template v-slot:header>
              {{$t("Balance")}}
            </template>
            <template v-slot="{ row }">
              {{
                '$' + parseFloat(row.Balance).toFixed(2)
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="Bonus"
          >
            <template v-slot:header>
              {{$t("Bonus")}}
            </template>
            <template v-slot="{ row }">
              {{
                '$' + parseFloat(row.Bonus).toFixed(2)
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="Status"
          >
            <template v-slot:header>
              {{$t("Status")}}
            </template>
          </el-table-column>
          <el-table-column
            prop="ActiveDateTime"
          >
            <template v-slot:header>
              {{$t("Active Date")}}
            </template>
            <template v-slot="{ row }">
              {{ row.ActiveDateTime ? row.ActiveDateTime.substr(0, 10) : '' }}
            </template>
          </el-table-column>
          <el-table-column min-width="50px" label="">
            <template slot-scope="scope">
              <span class="btn-inner--icon" 
                style="margin-right: 10px"
                @click="inquiry_view_card(scope.row, scope.$index)">
                <i class="ni ni-single-copy-04 icon-view">
                </i>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </b-card>
    </div>

    <!-- inquiry card modal -->
    <b-modal ref="inquiry_card_modal" :title="$t(inquiry_card_modal_title)" :size="inquiry_card_modal_size" id="inquiry_card_modal">
      <el-row :gutter="10">
        <el-col :span="12">
          <b-input-group class="mb-2 mt-2" :prepend="$t('Name') + '1:'">
            <b-input
              class="form-control height_adjust_input left_padding"
              v-model="inquiry_card_detail.Name1"
              disabled
            />
          </b-input-group>
        </el-col>
        <el-col :span="12">
          <b-input-group class="mb-2 mt-2" :prepend="$t('Name') + '2:'">
            <b-input
              class="form-control height_adjust_input left_padding"
              v-model="inquiry_card_detail.Name2"
              disabled
            />
          </b-input-group>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <b-input-group class="mb-2 mt-2" :prepend="$t('CardNo') + ':'">
            <b-input
              class="form-control height_adjust_input left_padding"
              v-model="inquiry_card_detail.CardNo"
              disabled
            />
          </b-input-group>
        </el-col>
        <el-col :span="12" v-if="inquiry_card_detail.EnableSerialNo == '1'">
          <b-input-group class="mb-2 mt-2" :prepend="$t('Serial Number') + ':'">
            <b-input
              class="form-control height_adjust_input left_padding"
              v-model="inquiry_card_detail.SerialNumber"
              disabled
            />
          </b-input-group>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="inquiry_card_detail.Type == 'ValueCard'">
        <el-col :span="12">
          <b-input-group class="mb-2 mt-2" :prepend="$t('Balance') + ':'">
            <b-input
              class="form-control height_adjust_input left_padding"
              v-model="inquiry_card_detail.Balance"
              disabled
            />
          </b-input-group>
        </el-col>
        <el-col :span="12">
          <b-input-group class="mb-2 mt-2" :prepend="$t('Bonus') + ':'">
            <b-input
              class="form-control height_adjust_input left_padding"
              v-model="inquiry_card_detail.Bonus"
              disabled
            />
          </b-input-group>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <b-input-group class="mb-2 mt-2" :prepend="$t('Charge Mode') + ':'">                  
            <el-select
              class="el-dropdown4"
              v-model="inquiry_card_detail.ChargeMode"
              disabled
            >
              <el-option
                v-for="(mode, idx) in charge_mode_list"
                :key="idx"
                :value="mode.value"
                :label="$t(mode.label)"
              >
              </el-option>
            </el-select>
          </b-input-group>
        </el-col>
        <el-col :span="12" v-if="inquiry_card_detail.Type == 'ValueCard'">
          <b-input-group class="mb-2 mt-2" :prepend="$t('Discount Rate') + ':'">
            <b-input
              class="form-control height_adjust_input left_padding"
              v-model="inquiry_card_detail.Discount"
              disabled
            />
            <div class="input_percentage">%</div>
          </b-input-group>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <b-input-group class="mb-2 mt-2" :prepend="$t('Reloadable') + ':'">
            <b-input
              class="form-control height_adjust_input left_padding"
              :value="inquiry_card_detail.CanReload == '1' ? $t('Yes') : $t('No')"
              disabled
            />
          </b-input-group>
        </el-col>
        <el-col :span="12">
          <b-input-group class="mb-2 mt-2" :prepend="$t('Transferable') + ':'">
            <b-input
              class="form-control height_adjust_input left_padding"
              :value="inquiry_card_detail.CanTransfer == '1' ? $t('Yes') : $t('No')"
              disabled
            />
          </b-input-group>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <!-- <el-col :span="12">
          <b-input-group class="mb-2 mt-2" :prepend="$t('Forced Binding VIP') + ':'">
            <b-input
              class="form-control height_adjust_input left_padding"
              :value="inquiry_card_detail.MustBindCustomer == '1' ? $t('Yes') : $t('No')"
              disabled
            />
          </b-input-group>
        </el-col> -->
        <el-col :span="12">
          <b-input-group class="mb-2 mt-2" :prepend="$t('Store') + ':'">
            <b-input
              class="form-control height_adjust_input left_padding"
              :value="inquiry_card_detail.Stores == '' ? $t('All Stores') : inquiry_card_detail.Stores"
              disabled
            />
          </b-input-group>
        </el-col>
      </el-row>

      <el-row :gutter="10" v-if="inquiry_card_detail.Type == 'TimesCard'">
        <el-table
          stripe
          class="table-responsive table card_table mt-2"
          style="width: 100%"
          header-row-class-name="thead-light"
          :span-method="inquiry_tc_detail_span_method"
          :data="inquiry_card_detail.ProductList"
          max-height="430px"
        >
          <!-- package -->
          <el-table-column label="Status" prop="Status" class="p-0">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Status") }}</div>
            </template>
          </el-table-column>
          <el-table-column label="ExpireDateTime" prop="ExpireDateTime" class="p-0">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Expire Date") }}</div>
            </template>
            <template v-slot="{ row }">
              {{ row.ExpireDateTime ? row.ExpireDateTime.substr(0, 10) : '' }}
            </template>
          </el-table-column>
          <!-- product -->
          <el-table-column label="Name1" prop="Name1" class="p-0">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Name") }}</div>
            </template>
          </el-table-column>
          <el-table-column label="Count" prop="Count" class="p-0">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Count") }}</div>
            </template>
            <template v-slot="{ row }">
              {{ parseFloat(row.Count).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="Bonus" prop="Bonus" class="p-0">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Bonus") }}</div>
            </template>
            <template v-slot="{ row }">
              {{ parseFloat(row.Bonus).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="Price" prop="Price" class="p-0">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Price") }}</div>
            </template>
            <template v-slot="{ row }">
              {{ parseFloat(row.Price).toFixed(2) }}
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click="inquiry_close_card_modal"
        >
          {{ $t('Close') }}
        </button>
      </template>
    </b-modal>
    
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Button,
  Row,
  Col,
  Select,
  Option,
} from "element-ui";
import { getStore } from "../../util/helper";

const axios = require("axios");

export default {
  name: 'CardList',
  props: ["inquiry_cards"],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  watch: {
  },
  data() {
    return {
      token: "",
      user_id: "",

      charge_mode_list: [{value:'Regular', label:'Regular'}, {value:'BonusEven', label:'Deduct bonus evenly'}],
      
      // card inquiry
      inquiry_card_detail: {},
      inquiry_card_modal_title: '',
      inquiry_card_modal_size: '',
      inquiry_span_info: [],
    };
  },
  computed: {
    apiBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
  },
  methods: {
    inquiry_view_card(card) {
      this.inquiry_card_detail = card;
      this.inquiry_span_info = card.span_info;
      this.inquiry_card_modal_title = 'Detail';
      this.inquiry_card_modal_size = 'xl';
      this.$refs["inquiry_card_modal"].show();
      this.calculate_el_select_width2();
    },
    inquiry_tc_detail_span_method({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1) {
        for (var i = 0; i < this.inquiry_span_info.length; i++) {
          if (rowIndex == this.inquiry_span_info[i][0]) {
            return {
              rowspan: this.inquiry_span_info[i][1],
              colspan: 1
            };
          }
        }
        return {
          rowspan: 0,
          colspan: 0
        };
      }
    },
    inquiry_close_card_modal() {
      this.$refs["inquiry_card_modal"].hide();
    },
    calculate_el_select_width2(){
      setTimeout(()=>{
        for(var i = 0; i < document.getElementsByClassName("el-dropdown4").length; i++){
          document.getElementsByClassName("el-dropdown4")[i].children[0].children[0].style.borderRadius = "0 0.375rem 0.375rem 0";
          document.getElementsByClassName("el-dropdown4")[i].style.width = (parseFloat(document.getElementsByClassName("height_adjust_input")[0].clientWidth)) + "px";
          document.getElementsByClassName("el-dropdown4")[i].children[0].children[0].style.height = "46px";
        }
      },1);
    },
  },
  mounted() {
    this.token = getStore("token");
    this.user_id = getStore("user_id");
    if (!this.token || !this.user_id) {
      this.$router.push("/login");
      return;
    }
  },
};
</script>
<style>
.el-table th {
  padding: 2px 0;
}
.left_padding {
  padding-left: 15px !important;
}
.card_table .btn-inner--icon .ni:hover {
  cursor: pointer;
}
.card_table .btn-inner--icon .icon-add {
  color: #404040;
  background-color: rgba(0, 255, 0, 0.5);
  border-radius: 50%;
  font-size: 1.8rem;
}
.card_table .btn-inner--icon .icon-view {
  color: #0d7924;
  font-size: 1.2rem;
  top: 1px;
}
.height_adjust_input {
  height: 46px;
}
.input_percentage {
  background-color: #F5F7FA;
  color: #909399;
  position: relative;
  border: 1px solid #DCDFE6;
  border-radius: 0 4px 4px 0;
  padding: 3px 20px;
  white-space: nowrap;
  font-size: 20px;
  font-weight: 600;
}
</style>